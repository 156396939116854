import { ChartData } from "chart.js";
import format from "date-fns/format";
import { defaultTo, get, isEmpty, isNumber, set } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { State } from "../reducers";
import { User } from "../reducers/user";
import { BESPOKEN_API_URL } from "./bespoken-api";
// TODO: check from where tp get value
export type LineChartData = ChartData<"line">;
export type DoughnutChartData = ChartData<"doughnut">;
export type TestRunResultsRows = {
    test_run_date: Date;
    test_run_id: string;
    project_id: string;
    project_name: string;
    customer_id: string;
    platform: string;
    client: string;
    test_suites: number;
    tests: number;
    test_suite_success_rate: number;
    test_suite_success_rate_label: "GREEN" | "YELLOW" | "RED";
};
export type TestRunSummaryRow = {
    slot_type: "level_1" | "level_2" | "level_3";
    slot_index: { suite: number, test: number, step: number };
    slot_status: string;
    slot_title: string;
    slot_content: {
        step_passed: number;
        step_failed: number;
        step_skipped: number;
        step_executed: number;
        step_total: number;
    };

    test_run_id: string;
    project_id: string;
    project_name: string;
    test_suite_name: string;
    test_execution_timestamp: Date;
    test_name: string;
    test_result: "PASSED" | "FAILED" | "SKIPPED";
    test_suite_result: "PASSED" | "FAILED" | "SKIPPED";
    step_passed: number;
    step_failed: number;
    step_skipped: number;
    step_executed: number;
    step_total: number;
    test_order: number;

    test_suite_id: string;
    test_result_id: string;
    locale: string;
};

export type TestRunBreakdown = {
    slot_type: "level_1" | "level_2" | "level_3";
    slot_index: { suite: number, test: number, step: number };
    slot_status: string;
    slot_title: string;
    slot_content: string | [{ error: string, error_timestamp: Date, utterance_audio_url: string }];

    test_run_id: string;
    test_conversation_id: string;
    project_id: string;
    test_execution_timestamp: string;
    test_suite_name: string;
    test_suite_result: string;
    test_name: string;
    test_result: string;
    message: string;
    assertion: string;
    transcript: string;
    result: string;
    errors: string;
    errors_timestamp: string;
    utterance_audio_url: string;
    step_raw_reponse: any;

    test_order: number;
    interaction_order: number;
    test_suite_id: string;
    test_result_id: string;
    locale: string;

    expanded?: boolean;
};

export type FilterParameters = {
    projectId?: string;
    platformId?: string;
    localeId?: string;
    startTimestamp?: Date;
    endTimestamp?: Date;
    searchKey?: number;
    page?: number;
    timezoneOffset?: number;
};
export type TestRunIdParameters = { customerId: string, testRunId: string };
export type TestIdParameters = { customerId: string, testRunId: string, testName: string };

export interface BespokenReportingApiProps {
    apiEndpoint?: URL;
    user: User;
    organizationId: string;
    userId: string;
}

export interface BespokenReportingApiState {
}

export const VALUE_UNDEFINED = "VALUE_UNDEFINED";
export const VALUE_NULL = "VALUE_NULL";

type ChartId = "selectKpiExecutedTests" | "selectKpiSuccessRate" | "selectKpiFailureRate" | "selectKpiAverageExecutionTime" | "selectTestRunsEvolutionOverTime" | "SelectGroupByPlatform" | "SelectGroupByClient" | "SelectGroupTestSuiteResultByRun" | "SelectGroupTestResultByRun";
type RequestId = "TestSummaryRequest" | "TestRunBreakdown" | "TestRunsList" | "TestRunsListExport";

const random = (min: number, max: number) => Math.floor((max - min + 1) * Math.random() + min);

const hashCode = (str: string) => {
    let hash = 0, i, chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
};
const randomColorDoughnutDictionary = (labels: string[]) => {
    const createRandom = () => `${random(0, 250)}, ${random(0, 250)}, ${random(0, 250)}`;
    const COLORS = [
        "0, 207, 128",
        "255, 222, 58",
        "68, 208, 210",
        "255, 125, 125",
        "255, 179, 52",
        "255, 93, 1",
        "25, 73, 107",
        "0, 188, 117",
        "197, 203, 211",
        "240, 243, 248",
    ];
    const opacity = "0.8";
    return labels
        .sort()
        .map((s, i) => {
            if (i >= COLORS.length) { return createRandom(); }
            return COLORS[i];
        })
        .map((s, i) => ({ label: labels[i], color: `rgba(${s}, ${opacity})` }))
        .reduce((p, { label, color }) => {
            set(p, label, color);
            return p;
        }, {});
};
const statusColorDoughnutDictionary = (labels: string[]) => {
    const green = "0, 207, 128";
    const red = "255, 125, 125";
    const yellow = "255, 222, 58";
    const opacity = "0.8";
    return { passed: `rgba(${green}, ${opacity})`, failed: `rgba(${red}, ${opacity})`, skipped: `rgba(${yellow}, ${opacity})` };
};

const REPORTING_API_URL = new URL(`${BESPOKEN_API_URL}/reporting`);

export class BespokenReportingApi extends React.Component<BespokenReportingApiProps, BespokenReportingApiState> {
    static defaultProps: BespokenReportingApiProps = {
        apiEndpoint: REPORTING_API_URL,
        user: undefined,
        userId: undefined,
        organizationId: undefined,
    };

    state: BespokenReportingApiState;
    props: BespokenReportingApiProps;

    constructor(props: BespokenReportingApiProps) {
        super(props);

        this.state = {};
    }

    async getProjects() {
        const newUrl = new URL(this.props.apiEndpoint.toString());
        newUrl.pathname = `/reporting/filters/${this.props?.organizationId}/projects`;

        const headers = {
            "Content-Type": "application/json",
            "x-access-token": process.env.SOURCE_API_ACCESS_TOKEN,
        };
        const method = "get";

        return fetch(newUrl.toString(), { method, headers }).then(res => res.json());
    }

    async getPlatforms() {
        const newUrl = new URL(this.props.apiEndpoint.toString());
        newUrl.pathname = `/reporting/filters/${this.props?.organizationId}/platforms`;

        const headers = {
            "Content-Type": "application/json",
            "x-access-token": process.env.SOURCE_API_ACCESS_TOKEN,
        };
        const method = "get";

        return fetch(newUrl.toString(), { method, headers }).then(res => res.json());
    }

    async getLocales() {
        const newUrl = new URL(this.props.apiEndpoint.toString());
        newUrl.pathname = `/reporting/filters/${this.props?.organizationId}/locales`;

        const headers = {
            "Content-Type": "application/json",
            "x-access-token": process.env.SOURCE_API_ACCESS_TOKEN,
        };
        const method = "get";

        return fetch(newUrl.toString(), { method, headers }).then(res => res.json());
    }

    async getClients() {
        const newUrl = new URL(this.props.apiEndpoint.toString());
        newUrl.pathname = `/reporting/filters/${this.props?.organizationId}/clients`;

        const headers = {
            "Content-Type": "application/json",
            "x-access-token": process.env.SOURCE_API_ACCESS_TOKEN,
        };
        const method = "get";

        return fetch(newUrl.toString(), { method, headers }).then(res => res.json());
    }

    async getTestRunsEvolutionOverTimeChartValues(filters: FilterParameters): Promise<LineChartData> {
        return this.fetchChartData("selectTestRunsEvolutionOverTime", filters)
            .then(data => {
                return data.resultSet.reduce((p: { labels: Array<string>, datasets: Array<any> }, c: any) => {
                    p.labels.push(format(new Date(c.id_date), "MM/dd"));

                    if (p.datasets.length === 0) {
                        p.datasets.push({ borderColor: "rgba(68, 208, 210, 1)", data: [], label: "test" });
                        p.datasets.push({ borderColor: "rgba(0, 207, 128, 1)", data: [], label: "test" });
                        p.datasets.push({ borderColor: "rgba(255, 125, 125, 1)", data: [], label: "test" });
                    }


                    p.datasets[0].data.push(parseInt(get(c, "total_executed")));
                    p.datasets[0].label = "Total test runs";

                    p.datasets[1].data.push(parseInt(get(c, "total_passed")));
                    p.datasets[1].label = "Successful test runs";

                    p.datasets[2].data.push(parseInt(get(c, "total_failed")));
                    p.datasets[2].label = "Failed test runs";

                    return p;
                }, { labels: [], datasets: [] });
            });
    }

    async fetchChartData(chartName: ChartId, filtersOrId: FilterParameters | TestRunIdParameters): Promise<any> {
        const u = (chartName: string, filters: FilterParameters | TestRunIdParameters) => {
            const newUrl = new URL(this.props.apiEndpoint.toString());

            if (chartName === "SelectGroupTestSuiteResultByRun" || chartName === "SelectGroupTestResultByRun") {
                const { testRunId, customerId } = filters as TestRunIdParameters;
                newUrl.pathname = `${newUrl.pathname}/charts/${chartName}/customers/${this.props?.organizationId}/testruns/${testRunId}/values`;
            } else {
                newUrl.pathname = `${newUrl.pathname}/charts/${chartName}/customers/${this.props?.organizationId}/values`;

                newUrl.search = "";
                for (const key in filters) {
                    const tempValue = get(filters, key);

                    if (tempValue === VALUE_UNDEFINED) {
                        continue;
                    }

                    const val = tempValue === VALUE_NULL ? "" : tempValue;

                    if (val instanceof Date) {
                        newUrl.searchParams.append(key, val.toJSON());
                        continue;
                    }

                    newUrl.searchParams.append(key, val);
                }
            }

            return newUrl.toString();
        };
        const method = "GET";
        const headers = {
            "Content-Type": "application/json",
            "x-access-token": process.env.SOURCE_API_ACCESS_TOKEN,
        };

        return fetch(u(chartName, filtersOrId), { method, headers })
            .then(res => res.json())
            .catch(err => {
                console.log(err);
                return { config: {}, resultSet: [] };
            });
    }

    async getExecutedTestsKPIValue(filters: FilterParameters): Promise<number> {
        return this.fetchChartData("selectKpiExecutedTests", filters)
            .then(({ resultSet }) => resultSet.pop())
            .then(({ total_executed }) => total_executed)
            .then((val: number) => {
                if (!isNumber(val)) {
                    return undefined;
                }
                return val;
            });
    }

    async getSuccessRateKPIValue(filters: FilterParameters): Promise<number> {
        return this.fetchChartData("selectKpiSuccessRate", filters)
            .then(({ resultSet }) => resultSet.pop())
            .then(({ rate_success }) => rate_success)
            .then((val: number) => {
                if (!isNumber(val)) {
                    return undefined;
                }
                return Math.floor((val * 100) * 10) / 10;
            });
    }

    async getFailureRateKPIValue(filters: FilterParameters): Promise<number> {
        return this.fetchChartData("selectKpiFailureRate", filters)
            .then(({ resultSet }) => resultSet.pop())
            .then(({ rate_failures }) => rate_failures)
            .then((val: number) => {
                if (!isNumber(val)) {
                    return undefined;
                }
                return Math.ceil((val * 100) * 10) / 10;
            });
    }

    async getAvgExecutionTimeKPIValue(filters: FilterParameters): Promise<number> {
        return this.fetchChartData("selectKpiAverageExecutionTime", filters)
            .then(({ resultSet }) => resultSet.pop())
            .then(({ average_seconds }) => average_seconds)
            .then((val: number) => {
                if (!isNumber(val)) {
                    return undefined;
                }
                return Math.floor(val * 10) / 10;
            });
    }

    async getTestRunsByPlatformChartValues(filters: FilterParameters): Promise<DoughnutChartData> {
        return this.fetchChartData("SelectGroupByPlatform", filters)
            .then(({ resultSet, config }) => {
                // calculates the color will be used baes on the name and the alphabetic order
                const colors = randomColorDoughnutDictionary(resultSet.map(({ platform_name }: { platform_name: string }) => platform_name));
                return { resultSet: resultSet.map(({ platform_name, group_counter }: { platform_name: string, group_counter: string }) => ({ platform_name, group_counter, backgroundColor: get(colors, platform_name) })), config };
            })
            .then(({ resultSet, config }) => resultSet.reduce((prev: { labels: string[], datasets: { data: number[], backgroundColor: string[] }[] }, { platform_name, group_counter, backgroundColor }: { platform_name: string, group_counter: number, backgroundColor: string }) => {
                prev.labels.push(platform_name);

                prev.datasets[0].data.push(group_counter);
                prev.datasets[0].backgroundColor.push(backgroundColor);

                return prev;
            }, { labels: [], datasets: [{ data: [], backgroundColor: [] }] }));
    }

    async getTestRunsByClientChartValues(filters: FilterParameters): Promise<DoughnutChartData> {
        return this.fetchChartData("SelectGroupByClient", filters)
            .then(({ resultSet, config }) => {
                // calculates the color will be used baes on the name and the alphabetic order
                const colors = randomColorDoughnutDictionary(resultSet.map(({ client_name }: { client_name: string }) => client_name));
                return { resultSet: resultSet.map(({ client_name, group_counter }: { client_name: string, group_counter: string }) => ({ client_name, group_counter, backgroundColor: get(colors, client_name) })), config };
            })
            .then(({ resultSet, config }) => resultSet.reduce((prev: { labels: string[], datasets: { data: number[], backgroundColor: string[] }[] }, { client_name, group_counter, backgroundColor }: { client_name: string, group_counter: number, backgroundColor: string }) => {
                prev.labels.push(client_name);

                prev.datasets[0].data.push(group_counter);
                prev.datasets[0].backgroundColor.push(backgroundColor);

                return prev;
            }, { labels: [], datasets: [{ data: [], backgroundColor: [] }] }));
    }

    async getTestRunsResultsTableValues(filters: FilterParameters): Promise<{ resultSet: Array<TestRunResultsRows>, config: object }> {
        return this.fetchTestRunData("TestRunsList", filters)
            .then(({ resultSet, config }) => ({ resultSet: resultSet.map((e: any) => ({ ...e, test_run_date: new Date(e.test_run_date) })), config }));
    }

    async getTestRunsResultsExport(filters: FilterParameters): Promise<{ resultSet: Array<TestRunResultsRows>, config: object }> {
        return this.fetchTestRunData("TestRunsListExport", filters)
    }

    async fetchTestRunData(urlType: RequestId, id: TestRunIdParameters | TestIdParameters | FilterParameters): Promise<any> {
        // {{bespoken-api-url}}/reporting/customers/{{user-id}}/testruns/c844d049-3dbd-44fe-ad48-b3a51ad7171f
        // {{bespoken-api-url}}/reporting/customers/{{user-id}}/testruns/95514072-29a4-4230-aef3-0d7c292c4d2a/tests/Pikachu wrong/steps
        // {{bespoken-api-url}}/reporting/charts/SelectGroupTestSuiteResultByRun/customers/rH75VTHQ01U7wrg5PmW2FKXWtD22/testruns/bf87a000-5294-4b83-b111-d15590364c2b/values
        // {{bespoken-api-url}}/reporting/charts/SelectGroupTestResultByRun/customers/rH75VTHQ01U7wrg5PmW2FKXWtD22/testruns/bf87a000-5294-4b83-b111-d15590364c2b/values
        const u = (id: TestRunIdParameters | TestIdParameters | FilterParameters) => {
            const newUrl = new URL(this.props.apiEndpoint.toString());
            if (urlType === "TestSummaryRequest") {
                const { customerId, testRunId } = id as TestRunIdParameters;
                newUrl.pathname = `${newUrl.pathname}/customers/${this.props.organizationId}/testruns/${testRunId}`;
            } else if (urlType === "TestRunBreakdown") {
                const { customerId, testRunId, testName } = id as TestIdParameters;
                newUrl.pathname = `${newUrl.pathname}/customers/${this.props.organizationId}/testruns/${testRunId}/breakdown`;
            } else if (urlType === "TestRunsList" || urlType === "TestRunsListExport") {
                newUrl.pathname = `${newUrl.pathname}/customers/${this.props.organizationId}/testruns`;
                if (urlType === "TestRunsListExport") newUrl.pathname += "-export";
                newUrl.search = "";

                const queryProps = { ...id };
                for (const key in queryProps) {
                    const tempValue = get(queryProps, key);

                    if (tempValue === VALUE_UNDEFINED) {
                        continue;
                    }

                    const val = tempValue === VALUE_NULL ? "" : tempValue;

                    if (val instanceof Date) {
                        newUrl.searchParams.append(key, val.toJSON());
                        continue;
                    }

                    newUrl.searchParams.append(key, val);
                }
            }

            return newUrl.toString();
        };
        const method = "GET";
        const headers = {
            "Content-Type": urlType.includes("Export") ? "application/csv" : "application/json",
            "x-access-token": process.env.SOURCE_API_ACCESS_TOKEN,
        };

        return fetch(u(id), { method, headers })
            .then(res => urlType.includes("Export") ? res.blob() : res.json())
            .catch(err => {
                console.log(err);
                return { config: {}, resultSet: [] };
            });
    }

    async getTestRunSummary(id: TestRunIdParameters): Promise<Array<TestRunSummaryRow>> {
        return this.fetchTestRunData("TestSummaryRequest", id)
            .then(({ resultSet }) => resultSet)
            .then(resultSet => resultSet.map((r: TestRunSummaryRow) => ({ ...r, test_execution_timestamp: new Date(r.test_execution_timestamp) })));
    }

    async getTestSuitesGroupedByResultForTestRun(id: TestRunIdParameters): Promise<DoughnutChartData> {
        return this.fetchChartData("SelectGroupTestSuiteResultByRun", id)
            .then(({ resultSet, config }) => {
                // calculates the color will be used baes on the name and the alphabetic order
                const colors = statusColorDoughnutDictionary(resultSet.map(({ result }: { result: string }) => result));
                return { resultSet: resultSet.map(({ result, group_counter }: { result: string, group_counter: string }) => ({ result, group_counter, backgroundColor: get(colors, result) })), config };
            })
            .then(({ resultSet, config }) => resultSet.reduce((prev: { labels: string[], datasets: { data: number[], backgroundColor: string[] }[] }, { result, group_counter, backgroundColor }: { result: string, group_counter: number, backgroundColor: string }) => {
                prev.labels.push(result);

                prev.datasets[0].data.push(group_counter);
                prev.datasets[0].backgroundColor.push(backgroundColor);

                return prev;
            }, { labels: [], datasets: [{ data: [], backgroundColor: [] }] }));
    }

    async getTestsGroupedByResultForTestRun(id: TestRunIdParameters): Promise<DoughnutChartData> {
        return this.fetchChartData("SelectGroupTestResultByRun", id)
            .then(({ resultSet, config }) => {
                // calculates the color will be used baes on the name and the alphabetic order
                const colors = statusColorDoughnutDictionary(resultSet.map(({ result }: { result: string }) => result));
                return { resultSet: resultSet.map(({ result, group_counter }: { result: string, group_counter: string }) => ({ result, group_counter, backgroundColor: get(colors, result) })), config };
            })
            .then(({ resultSet, config }) => resultSet.reduce((prev: { labels: string[], datasets: { data: number[], backgroundColor: string[] }[] }, { result, group_counter, backgroundColor }: { result: string, group_counter: number, backgroundColor: string }) => {
                prev.labels.push(result);

                prev.datasets[0].data.push(group_counter);
                prev.datasets[0].backgroundColor.push(backgroundColor);

                return prev;
            }, { labels: [], datasets: [{ data: [], backgroundColor: [] }] }));
    }

    async getTestRunBreakdown(id: TestRunIdParameters): Promise<Array<TestRunBreakdown>> {
        return this.fetchTestRunData("TestRunBreakdown", id)
            .then(({ resultSet }) => resultSet);
    }

    render(): false { return false; }
}

function mapStateToProps(state: State.All) {
    return {
        user: state.user?.currentUser,
        organizationId: state?.organization?.selectedOrganization?.id,
        userId: state?.context?.authUser?.id
    };
}

function mapDispatchToProps(dispatch: any) {
    return {};
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    undefined,
    { withRef: true }
)(BespokenReportingApi);

export const updateReportResultsProjectName = async (params: { customerId: string, projectId: string, projectName: string }): Promise<void> => {
    if (isEmpty(params.projectId)) {
        throw new Error(`Project id cannot be empty`);
    }
    if (isEmpty(params.customerId)) {
        throw new Error(`Customer id cannot be empty`);
    }
    if (isEmpty(params.projectName)) {
        throw new Error(`Project name cannot be empty`);
    }

    const u = ({ projectId, customerId }: { projectId: string, customerId: string }) => {
        const newUrl = new URL(REPORTING_API_URL.toString());
        newUrl.pathname = `${newUrl.pathname}/customers/${customerId}/projects/${projectId}`;
        return newUrl.toString();
    };
    const { projectName } = params;

    const method = "PUT";
    const headers = {
        "Content-Type": "application/json",
        "x-access-token": process.env.SOURCE_API_ACCESS_TOKEN,
    };

    return fetch(u(params), { method, headers, body: JSON.stringify({ projectName }) })
        .then(res => res.json())
        .catch(err => {
            console.log(err);
            return { config: {}, resultSet: [] };
        });
};
