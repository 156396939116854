import { chain, includes, isEmpty, isFunction, map } from "lodash";
import {
    CREATE_ORGANIZATION,
    CREATE_VIRTUAL_DEVICE,
    CREATE_VIRTUAL_DEVICE_WITH_OAUTH_LINK,
    DELETE_VIRTUAL_DEVICE,
    FETCH_ORGANIZATION,
    FETCH_SOURCES,
    FETCH_VIRTUAL_DEVICE,
    REFRESH_VIRTUAL_DEVICE_WITH_OAUTH_LINK,
    REFRESH_VIRTUAL_DEVICES_STATE,
    RESET_ORGANIZATION_STATE,
    SWITCH_ORGANIZATION,
    UPDATE_ORGANIZATION,
    UPDATE_SOURCE,
    UPDATE_VIRTUAL_DEVICE,
} from "../constants/organization";
import { State } from "../reducers/index";
import { OrganizationSource, OrganizationState, VirtualDeviceRequest, VirtualDevices } from "../reducers/organization";
import { User } from "../reducers/user";
import { fetchInternalApi, testSuiteMapper, virtualDeviceMapper } from "../services/internal-api";
import { tryParseDate } from "../utils/ReactHelpers";
import { setLatestOrganizationId } from "./context";


type Callback0 = (err?: Error) => void;
type Callback2 = (err: Error, data?: any) => void;
type DispatchFn<T> = (dispatch: () => any) => T;
type DispatchAndStoreFn<T> = (dispatch: () => any, getState: () => State.All) => T;

export type FetchOrganization = {
    type: FETCH_ORGANIZATION,
    organizationId: string,
    callback?: () => void
};
export function fetchOrganization(organizationId: string = "default", callback: Callback2 = err => { }): DispatchAndStoreFn<FetchOrganization> {
    return function (dispatch: any, getState: () => State.All) {

        const userId = getState().context?.authUser?.id;

        if (isEmpty(userId)) { return { type: FETCH_ORGANIZATION, organizationId }; }

        if (isEmpty(organizationId)) { organizationId = "default"; }
        if (organizationId === "current") { organizationId = getState()?.organization?.selectedOrganization?.id; }

        const promise = Promise.all([
            fetchInternalApi(`/users/${userId}/organizations`),
            fetchInternalApi(`/users/${userId}/organizations/${organizationId}`),
        ])
            .then(({ 0: organizations, 1: selectedOrganization }) =>
                ({ organizations, selectedOrganization: { ...selectedOrganization } }))
            .then((organizationState: OrganizationState) => {
                organizationState.selectedOrganization.invitations = chain(organizationState?.selectedOrganization?.invitations)
                    .defaultTo([])
                    .map(it => ({
                        ...it,
                        creationDate: tryParseDate(it.creationDate),
                        sentDate: tryParseDate(it.sentDate)
                    }))
                    .value();

                organizationState.selectedOrganization.members = chain(organizationState?.selectedOrganization?.members)
                    .defaultTo([])
                    .map(it => ({
                        ...it,
                        addedDate: tryParseDate(it.addedDate),
                        lastActive: tryParseDate(it.lastActive)
                    }))
                    .value();

                delete organizationState.selectedOrganization.virtualDevices;
                return organizationState;
            })
            .then(organizationState => {
                dispatch(updateOrganization(organizationState));
                dispatch(setLatestOrganizationId(organizationState?.selectedOrganization?.id));
                dispatch(fetchVirtualDevices());
                return organizationState.selectedOrganization;
            });

        if (isFunction(callback)) {
            promise
                .then((organization) => {
                    console.info('fetch organization completed')
                    callback(undefined, organization)
                });
        } else {
            promise.then((organization) => {
                console.info('fetch organization completed')
            });
        }

        promise.catch(err => callback(err));

        return {
            type: FETCH_ORGANIZATION,
            organizationId
        };
    };
}


export type UpdateOrganization = {
    type: UPDATE_ORGANIZATION,
    organizationState: OrganizationState

};
export function updateOrganization(organizationState: OrganizationState): UpdateOrganization {
    return {
        type: UPDATE_ORGANIZATION,
        organizationState
    };
}


export type SwitchOrganization = {
    type: SWITCH_ORGANIZATION,
    organizationId: string,
    callback?: () => void
};
export function switchOrganization(organizationId: string, callback: Callback0 = err => { }): DispatchFn<SwitchOrganization> {

    return (dispatch: any) => {
        dispatch(fetchOrganization(organizationId, () => {
            dispatch(fetchSources(organizationId, callback))
        }));

        return {
            type: SWITCH_ORGANIZATION,
            organizationId
        };
    };
}


export type FetchSources = {
    type: FETCH_SOURCES,
    organizationId: string,
    callback?: () => void
};
export function fetchSources(organizationId: string = "default", callback: Callback0 = err => { }): DispatchAndStoreFn<FetchSources> {
    return function (dispatch: any, getState: () => State.All) {
        const userId = getState().context?.authUser?.id;

        if (isEmpty(userId)) { return { type: FETCH_SOURCES, organizationId }; }

        if (isEmpty(organizationId)) { organizationId = "default"; }
        if (organizationId === "current") { organizationId = getState()?.organization?.selectedOrganization?.id; }
        if (organizationId === undefined ) {
            organizationId = "default";
        }
        const promise = fetchInternalApi(`/users/${userId}/organizations/${organizationId}/testsuites`)
            .then(testSuites => map(testSuites, it => testSuiteMapper(it)))
            .then((sources: OrganizationSource[]) => dispatch(updateSources(sources)));

        if (isFunction(callback)) {
            promise
                .then(() => {
                    callback(undefined)
                });

        }

        promise.catch(err => callback(err));

        return {
            type: FETCH_SOURCES,
            organizationId
        };
    };
}


export type UpdateSources = {
    type: UPDATE_SOURCE,
    organizationSources: OrganizationSource[]
};
export function updateSources(organizationSources: OrganizationSource[]): UpdateSources {
    return {
        type: UPDATE_SOURCE,
        organizationSources
    };
}


export type CreateVirtualDevice = {
    type: CREATE_VIRTUAL_DEVICE,
    virtualDeviceRequest: VirtualDeviceRequest,
    callback?: () => void
};
export function createVirtualDevice(virtualDeviceRequest: VirtualDeviceRequest, callback: Callback0 = err => { }): DispatchAndStoreFn<CreateVirtualDevice> {
    return function (dispatch: any, getState: () => State.All) {
        const platforms = getState().organization?.selectedOrganization?.subscription?.platforms;

        if (!includes(platforms, virtualDeviceRequest?.platform)) { throw new Error(`The platform provided does not exiting in the current organization. ${virtualDeviceRequest?.platform}`); }

        if (includes(["google", "alexa"], virtualDeviceRequest?.platform)) { throw new Error(`The platform provided requieres OAuth consent screen.`); }

        const promise = fetchInternalApi(
            ({ userId, organizationId }) => `/users/${userId}/organizations/${organizationId}/virtualdevices`,
            "POST",
            virtualDeviceRequest
        );

        if (isFunction(callback)) {
            promise.then(() => callback());
        }

        promise.catch(err => callback(err));

        return {
            type: CREATE_VIRTUAL_DEVICE,
            virtualDeviceRequest
        };
    };
}


export type CreateVirtualDeviceWithOAuthLink = {
    type: CREATE_VIRTUAL_DEVICE_WITH_OAUTH_LINK,
    virtualDeviceRequest: VirtualDeviceRequest,
    callback?: () => void
};
export function createVirtualDeviceWithOAuth(virtualDeviceRequest: VirtualDeviceRequest, callback: Callback2 = err => { }): DispatchAndStoreFn<CreateVirtualDeviceWithOAuthLink> {
    return function (dispatch: any, getState: () => State.All) {
        // const platformId = getState().organization?.selectedOrganization?.benefits?.platformIds;
        const platforms = getState().organization?.selectedOrganization?.subscription?.platforms;

        if (!includes(platforms, virtualDeviceRequest?.platform)) { throw new Error(`The platform provided does not exiting in the current organization. ${virtualDeviceRequest?.platform}`); }

        if (!includes(["google", "alexa"], virtualDeviceRequest?.platform)) { throw new Error(`The platform not supports OAuth consent screen.`); }

        if (isEmpty(virtualDeviceRequest?.returnUrl)) { throw new Error(`The virtualDeviceRequest.returnUrl must be provided`); }

        if (virtualDeviceRequest?.platform === "alexa") {
            // To force user the Alexa Music
            virtualDeviceRequest.parameters = { alexaMusic: true };
        }

        const promise = fetchInternalApi(
            ({ userId, organizationId }) => `/users/${userId}/organizations/${organizationId}/virtualdevices/oauth`,
            "POST",
            virtualDeviceRequest
        );

        if (isFunction(callback)) {
            promise.then(data => callback(undefined, data));
        }

        promise.catch(err => callback(err));

        return {
            type: CREATE_VIRTUAL_DEVICE_WITH_OAUTH_LINK,
            virtualDeviceRequest
        };
    };
}


export type RefreshVirtualDeviceWIthOAuthLink = {
    type: REFRESH_VIRTUAL_DEVICE_WITH_OAUTH_LINK;
    virtualDeviceRequest: VirtualDeviceRequest,
    callback?: () => void
};
export function refreshVirtualDeviceWithOAuthLink(virtualDeviceRequest: VirtualDeviceRequest, callback: Callback2 = err => { }): DispatchFn<RefreshVirtualDeviceWIthOAuthLink> {
    return function () {
        if (isEmpty(virtualDeviceRequest?.returnUrl)) { throw new Error(`The virtualDeviceRequest.returnUrl must be provided`); }

        const promise = fetchInternalApi(
            ({ userId, organizationId }) => `/users/${userId}/organizations/${organizationId}/virtualdevices/oauth/${virtualDeviceRequest?.token}`,
            "POST",
            virtualDeviceRequest
        );

        if (isFunction(callback)) {
            promise.then(data => callback(undefined, data));
        }

        promise.catch(err => callback(err));

        return {
            type: REFRESH_VIRTUAL_DEVICE_WITH_OAUTH_LINK,
            virtualDeviceRequest
        };
    };
}


export type DeleteVirtualDevice = {
    type: DELETE_VIRTUAL_DEVICE,
    token: string,
    callback?: () => void
};
export function deleteVirtualDevice(token: string, callback: Callback2 = err => { }): DispatchFn<DeleteVirtualDevice> {
    return function () {
        const promise = fetchInternalApi(
            ({ userId, organizationId }) => `/users/${userId}/organizations/${organizationId}/virtualdevices/${token}`,
            "DELETE"
        );

        if (isFunction(callback)) {
            promise.then(data => callback(undefined, data));
        }

        promise.catch(err => callback(err));

        return {
            type: DELETE_VIRTUAL_DEVICE,
            token
        };
    };
}


export type UpdateVirtualDevice = {
    type: UPDATE_VIRTUAL_DEVICE,
    virtualDevice: VirtualDevices,
    callback?: () => void
};
export function updateVirtualDevice(virtualDevice: VirtualDevices, callback: Callback2 = err => { }): DispatchFn<UpdateVirtualDevice> {
    return function () {
        const { name, token } = virtualDevice;
        const promise = fetchInternalApi(
            ({ userId, organizationId }) => `/users/${userId}/organizations/${organizationId}/virtualdevices/${token}`,
            "PUT",
            { name }
        );

        if (isFunction(callback)) {
            promise.then(data => callback(undefined, data));
        }

        promise.catch(err => callback(err));

        return {
            type: UPDATE_VIRTUAL_DEVICE,
            virtualDevice
        };
    };
}


export type FetchVirtualDevice = {
    type: FETCH_VIRTUAL_DEVICE,
    callback?: () => void
};
export function fetchVirtualDevices(callback: Callback2 = err => { }): DispatchFn<FetchVirtualDevice> {
    return function (dispatch: any) {
        const promise = fetchInternalApi(
            ({ userId, organizationId }) => `/users/${userId}/organizations/${organizationId}/virtualdevices`,
            "GET"
        );

        promise.then(data => {
            dispatch(refreshVirtualDeviceState(map(data, it => virtualDeviceMapper(it))));
            if (isFunction(callback)) {
                callback(undefined, data);
            }
         });


        promise.catch(err => callback(err));

        return {
            type: FETCH_VIRTUAL_DEVICE
        };
    };
}

export type RefreshVirtualDeviceState = {
    type: REFRESH_VIRTUAL_DEVICES_STATE,
    virtualDevices: VirtualDevices[],
    callback?: () => void
};
export function refreshVirtualDeviceState(virtualDevices: VirtualDevices[]): RefreshVirtualDeviceState {
    return {
        type: REFRESH_VIRTUAL_DEVICES_STATE,
        virtualDevices
    };
}

export type CreateOrganization = {
    type: CREATE_ORGANIZATION,
    user: User,
    callback?: () => void
};
export function createOrganization(user: User, callback: Callback2 = err => { }): DispatchAndStoreFn<CreateOrganization> {
    return function (dispatch: any, getState: () => State.All) {
        const { id } = getState().context?.authUser || {};

        const promise = fetchInternalApi(`/users/${id}/organizations`, "POST", user);
        promise.then((organization: any) => {
            // dispatch(updateUser({ currentUser: user }));
            console.log(organization);
            return organization;
        });

        if (isFunction(callback)) {
            promise
                .then((organization) => callback(undefined, organization));
        }

        promise.catch(err => callback(err));
        return {
            type: CREATE_ORGANIZATION,
            user,
        };
    };
}

export type ResetOrganizationState = {
    type: RESET_ORGANIZATION_STATE,
};
// export function resetOrganizationState(callback: Callback2 = err => { }): DispatchAndStoreFn<ResetOrganizationState> {
//     return function (dispatch: any, getState: () => State.All) {}(
//         return {
//             type: RESET_ORGANIZATION_STATE,
//         };
//     )}

export type OrganizationReduxActions = FetchOrganization | UpdateOrganization | SwitchOrganization | FetchSources | UpdateSources | CreateVirtualDevice | CreateVirtualDeviceWithOAuthLink | RefreshVirtualDeviceWIthOAuthLink | DeleteVirtualDevice | RefreshVirtualDeviceState | ResetOrganizationState;
