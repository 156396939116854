exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".DatePickerTheme__header--YIaen {\n  background-color: #19496b; }\n\n.DatePickerTheme__day--1AhEi.DatePickerTheme__active--C6aOk span {\n  background-color: #539ac2; }\n\n.DatePickerTheme__button--3bYLH {\n  color: #19496a !important; }\n\n.DatePickerTheme__calendar--IuwRi {\n  color: #19496b;\n  font-family: 'Open Sans', sans-serif;\n  font-size: 400;\n  font-size: 14px;\n  line-height: 14px; }\n", "",{"version":3,"sources":["/home/runner/work/dashboard/dashboard/src/components/DatePicker/DatePickerTheme.scss"],"names":[],"mappings":"AAoBA;EAnBI,yBAAkC,EAAA;;AAuBtC;EAnBI,yBAAmC,EAAA;;AA2BvC;EAvBI,yBAAkC,EAAA;;AA2BtC;EAvBI,cAAc;EACd,oCAAoC;EACpC,cAAc;EACd,eAAe;EACf,iBAAiB,EAAA","file":"DatePickerTheme.scss","sourcesContent":["@mixin header_panel {\n    background-color: rgb(25, 73, 107);\n}\n\n@mixin selected_date {\n    background-color: rgb(83, 154, 194);\n}\n\n@mixin dialog_buttons {\n    color: rgb(25, 73, 106) !important;\n}\n\n@mixin font_family {\n    color: #19496b;\n    font-family: 'Open Sans', sans-serif;\n    font-size: 400;\n    font-size: 14px;\n    line-height: 14px;\n}\n\n.header {\n    @include header_panel()\n}\n\n.day {\n    &.active {\n        span {\n            @include selected_date()\n        }\n    }\n}\n\n.button {\n    @include dialog_buttons()\n}\n\n.calendar{\n    @include font_family()\n}"],"sourceRoot":""}]);

// Exports
exports.locals = {
	"header": "DatePickerTheme__header--YIaen",
	"day": "DatePickerTheme__day--1AhEi",
	"active": "DatePickerTheme__active--C6aOk",
	"button": "DatePickerTheme__button--3bYLH",
	"calendar": "DatePickerTheme__calendar--IuwRi"
};